@tailwind base;
@tailwind components;
@tailwind utilities;

// body {
//   @apply bg-black ;
// }

// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus, 
// input:-webkit-autofill:active {
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: #FFF;
//     -webkit-transition: background-color 5000s ease-in-out 0s;
//     box-shadow: inset 0 0 20px 20px transparent;
//     caret-color: #FFF;
// }

:root {
  --primary: #6B22BE;
  --primaryLight: #f9f0ff;
  --primaryDark: #400086;
  --secondary: #00A5A8;
  --success: #52c41a;
  --processing: #1677ff;
  --error: #C83333;
  --background: #F2F2F2;
  --secondaryBackground: #ffffff;
  --transparent: transparent;
  --siderBg: #FAFAFA;
  --primaryText: #080808;
  --secondaryText: #393939;
  --quaternaryText: rgba(0, 0, 0, 0.25);
  --cardBackgroundColor: #FAFAFA;
  --borderColor: #d9d9d9;
  --secondaryBorderColor: #f0f0f0;
  --textDisabled: #CCCCCC;
  --conversationOutputBg: #0416172a;
  --conversationFileViewBg: #0A1B1C;
  --conversationInputBg: #FAFAFA;
  --hyperLinkColor: #9B59B6;
  --nodeCompleted: #DDF3E6;
  --nodeWaitingForApproval: #FFF4B5;
  --nodeApproved: #C4D7FF;
  --bgError: #fff2f0;
  --borderError: #ffe8e6;
  --colorInfo: #1677ff;
  --colorInfoBg: #e6f4ff;
  --colorInfoBorder: #91caff;
  --colorSuccessBg: #f6ffed;
  --colorSuccessBorder: #b7eb8f;
  --colorWarning: #faad14;
  --colorWarningBg: #fffbe6;
  --colorWarningBorder: #ffe58f;
  --menuItemHoverBG: #d9f2f2;
  --menuItemSelectedBG: #b2e0e0;
  --chatInputBackground: #F2F2F2;
}

body.dark-mode {
  --primary: #B583FF;
  --primaryLight: #362046;
  --primaryDark: #6F00C6;
  --secondary: #17B3B6;
  --success: #4CAF50;
  --processing: #62B6FF;
  --error: #FF6B6B;
  --background: #121212;
  --secondaryBackground: #0D0D0D;
  --transparent: transparent;
  --siderBg: #080808;
  --primaryText: #FFFFFF;
  --secondaryText: #B3B3B3;
  --quaternaryText: #393939;
  --cardBackgroundColor: #1E1E1E;
  --borderColor: #4D4D4D;
  --secondaryBorderColor: #3A3A3A;
  --textDisabled: #666666;
  --conversationOutputBg: #0A1B1C;
  --conversationFileViewBg: #0A1B1C;
  --conversationInputBg: #1E1E1E;
  --hyperLinkColor: #9B59B6;
  --nodeCompleted: #2A3B2F;
  --nodeWaitingForApproval: #554C2A;
  --nodeApproved: #2A3B55;
  --bgError: #4d3836;
  --borderError: #3d2e2d;
  --colorInfo: #5ecaff;
  --colorInfoBg: #002b48;
  --colorInfoBorder: #17406d;
  --colorSuccess: #52c41a;
  --colorSuccessBg: #132a13;
  --colorSuccessBorder: #1c561e;
  --colorWarning: #faad14;
  --colorWarningBg: #402c00;
  --colorWarningBorder: #614700;
  --menuItemHoverBG: #041617;
  --menuItemSelectedBG: #0b3232;
  --chatInputBackground: #1E1E1E;
}





.ant-typography {
  font-family: var(--font-inter-tight), sans-serif !important;
}

body, .ant, .ant-tabs {
  font-family: var(--font-inter-tight), sans-serif !important;
}

.ant-typography, .ant-btn, .ant-input {
  font-family: var(--font-inter-tight), sans-serif !important;
}

.unsupported-field{
    display:none !important;
}
.reactflow-link-hide{
    .react-flow__attribution{
        display: none;
    }
}
.inter-tight-100 {
    font-family: var(--font-inter-tight), sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
  }
  .inter-tight-200 {
    font-family: var(--font-inter-tight), sans-serif;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
  }
  .inter-tight-300 {
    font-family: var(--font-inter-tight), sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }
  .inter-tight-400 {
    font-family: var(--font-inter-tight), sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  .inter-tight-500 {
    font-family:var(--font-inter-tight), sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
  .inter-tight-600 {
    font-family: var(--font-inter-tight), sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
  }
  .inter-tight-700 {
    font-family: var(--font-inter-tight), sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
  }
  .inter-tight-800 {
    font-family: var(--font-inter-tight), sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
  }
  .inter-tight-900 {
    font-family: var(--font-inter-tight), sans-serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
  }

  .ant-checkbox-inner{
   border: 2px solid #005b5c;
  }

  .ant-tooltip-inner{
    text-align: center !important;
  }

  .autobots-sider-menu .ant-menu-item {
    height: 48px !important;
    padding: 16px !important;
    
  }
  .list-disc{
    *{
      color:white
    }
  }
  .ant-collapse-header{
    align-items: center !important;
  }

  .ant-btn {
    transition: transform 0.2s ease;
  }
  
  .ant-btn-primary:hover {
    transform: translateY(-2px);
  }
  
  .ant-btn-default:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)
  }
 
  .ant-modal-close-x {
    color: #C83333; 
  }

  .ant-modal-mask {
    backdrop-filter: blur(50px) !important; 
    background-color: rgba(255, 255, 255, 0.1) !important; 
  }

  .custom-tab {
    .ant-tabs-nav {
      margin: 0 !important;
      font-weight: 400 !important;
    }
  }

  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #6b22be !important;
  }

  .ant-tabs .ant-tabs-tab:hover {
    color: #7b42bd !important;
  }
  .ant-btn-primary:disabled,.ant-btn-primary.ant-btn-disabled {
    background-color: #70707070 !important; 
    background: #70707070 !important; 
    color: rgba(0, 0, 0, 0.644) !important;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-btn-default:disabled,.ant-btn-primary.ant-btn-disabled {
    background-color: #70707070 !important; 
    background: #70707070 !important; 
    color: rgba(0, 0, 0, 0.644) !important;
    border-color: #ffffff !important;
    box-shadow: none !important;
  }


  .custom-input-app-link {  
    height: 41px;
    transition: border-color 0.3s;
  }

  
  .custom-input-app-link:hover {
    border-color: #6B22BE !important;
  }
  
   .custom-input-app-link:focus {
     border-color: #6B22BE !important;
     box-shadow: 0 0 0 2px rgba(155, 5, 255, 0.06) !important;
   }

   .ant-card-hoverable:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  }

  .language-plaintext{
    white-space: normal;
  }

  .custom-dashboard-row td{
    border-bottom: none !important;
    padding:10px !important
  }

  .custom-dashboard-row th{
    border-bottom: none !important;
    padding:10px !important
  }

 ::-webkit-scrollbar {
    width: 10px !important;
}

::-webkit-scrollbar-track {
    background: var(--background) !important;
    border-radius: 16px !important;
    margin: 10px !important;
}

::-webkit-scrollbar-thumb {
    background: var(--menuItemSelectedBG) !important;
    border: 3px solid transparent !important;
    background-clip: content-box !important;
    border-radius: 16px !important;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--menuItemSelectedBG) !important;
    border: 3px solid transparent !important;
    background-clip: content-box !important;
    border-radius: 16px !important;
}

  // body.dark-mode {
  //   scrollbar-color: rgba(0, 0, 0, 0.25) rgba(245, 245, 245, 0.13); /* thumb color, track color */
  // }


//   body.dark-mode .menu-scroll-container {
//     // scrollbar-color: auto !important; /* Reset scrollbar-color to default so WebKit styles work */
//     scrollbar-width: thin !important;
// }
  

  body.dark-mode .ant-select-fine-tune :where(.ant-select-selection-placeholder) {
    color: #B3B3B3; /* Change this to your desired color */
    opacity: 1; /* Ensures the color appears as intended */
  }